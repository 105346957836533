<template>
  <div :class="$style.layout_default_content">
    <OrganismsHeader
      :navigations="headerNavigations"
      :product-categories="productCategories"
      :header-banner="headerBanner"
      :is-not-show-banner="isNotShowBanner"
      @on-click-search-sp="isShowSearchSideBarSP = true"
      @on-click-show-sp-nav="isShowSpSideNavbar = true"
    />

    <!-- SPメニュー -->
    <OrganismsSidebar
      :navigations="headerNavigations"
      :is-show="isShowSpSideNavbar"
      @close="isShowSpSideNavbar = false"
    />
    <div
      :class="[$style.sidebar_overlay, { [$style.is_show]: isShowSpSideNavbar }]"
      @click="isShowSpSideNavbar = false"
    />

    <!-- SP検索サイドバー -->
    <template v-if="productCategories">
      <div :class="[$style.search_sidebar, { [$style.is_show]: isShowSearchSideBarSP }]">
        <AtomsConsumerButton :class="$style.close" @click="closeSearchSideBarSP" />
        <OrganismsSearchForm :product-categories="productCategories" @on-close="closeSearchSideBarSP" />
      </div>
    </template>
    <div
      :class="[$style.search_sidebar_overlay, { [$style.is_show]: isShowSearchSideBarSP }]"
      @click="closeSearchSideBarSP"
    />

    <CartAddCartModal />

    <!-- クイックビュー -->
    <OrganismsQuickProductViewModal />

    <!-- 共通モーダル -->
    <OrganismsConfirmDialog />
    <OrganismsToast />
    <CommonModalGetCouponModal />

    <div
      :class="[
        $style.main_content,
        { [$style.no_show_banner]: !headerBanner || isNotShowBanner, [$style.not_padding_bottom]: notBottomPadding },
      ]"
    >
      <slot name="nuxt-page" />
    </div>

    <slot name="footer-fixed-contents" />
    <OrganismsFooter :navigations="footerNavigations" />
  </div>
</template>

<script setup lang="ts">
import dayjs from "dayjs"
import { useStore } from "~/stores"
import { useCustomMeta } from "~/composables/meta"
import { appendKarteScripts, removeKarteScripts } from "~/utils/functions/karte"
import { crossoverTag } from "~/utils/functions/affiliate"
import { criteoTag } from "~/utils/functions/crto"
import { yahooGeneralTag, yahooRetargetingScript, yahooRetargetingTag } from "~/utils/functions/yahoo"
import { mercariGeneralTag, mercariRetargetingTag } from "~/utils/functions/mercari"
import { fbPixel } from "~/utils/const/facebook"
import { MetaInfo, ScriptPropertySrc, ScriptPropertyText } from "~/types/meta"
import { useAdTrackingCreateMutation, usePublicAdFindOneQuery } from "~/gql/urql.generated"
import type { ApiLayoutOneResponse } from "~/types/server/api/layout"
import { useCart } from "~/composables/useCart"

const store = useStore()
const route = useRoute()
const config = useRuntimeConfig()
const customMeta = useCustomMeta()
const nuxtApp = useNuxtApp()

const { fetchGiftPattern } = useGift()
const { cartState } = useCart()

const { executeMutation: createAdTracking } = useAdTrackingCreateMutation()
const { setVisitStore, checkAndUpdateExpiration } = useStoreOrder()

const isNotShowBanner = computed(() => {
  // バナーを表示しないpath
  const notShowPaths = ["/order/new", "/story", "/general-medical-devices"]
  return notShowPaths.includes(route.path)
})

const headerNavigations = computed(() => {
  const header = layoutData?.navigation.find((nav) => nav.layout_type === "header")
  return header?.navigations
})

const footerNavigations = computed(() => {
  const footer = layoutData?.navigation.find((nav) => nav.layout_type === "footer")
  return footer?.navigations
})
const [layoutOneResponse, { data: newrelic }] = await Promise.all([
  $fetch<ApiLayoutOneResponse>("/api/layout/one", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }),
  useFetch("/api/newrelic"),
  fetchGiftPattern(true),
])

const layoutData = layoutOneResponse.status === 200 ? layoutOneResponse.data : null

const productCategories = computed(() => {
  return layoutData?.productCategories
})

const headerBanner = computed(() => {
  return layoutData?.banner
})

const isShowSearchSideBarSP = ref(false)
const isShowSpSideNavbar = ref(false)
const closeSearchSideBarSP = () => {
  isShowSearchSideBarSP.value = false
}

const addRouteLog = (BASE_URL: string) => {
  store.addRouteLog({
    url: BASE_URL + route.fullPath,
    stamped_unixtime: dayjs().unix() * 1000,
  })
}

const notBottomPaddingPaths = ["/business", "/bakune/cm", "/athletecollection", "features/christmas2024"]

const notBottomPadding = computed(() => {
  return notBottomPaddingPaths.some((path) => route.fullPath.includes(path))
})

const addTrackingInformation = async (BASE_URL: string) => {
  // 参照先が自サイト以外の場合、トラフィックをセット
  if (!document.referrer.includes(`${BASE_URL}`) && store.trafficParams && !store.trafficParams.full_path) {
    store.setTrafficParams(document.referrer)
  }

  // 広告クエリパラメータがある場合、トラッキング情報をセット
  if (route.query.u) {
    try {
      // 広告トラッキング情報をセット
      store.trackingPost({
        ad_url: typeof route.query?.u === "string" ? route.query.u : String(route.query.u),
        to_url: route.fullPath,
      })

      // 広告情報を取得
      const { data: adResult } = await usePublicAdFindOneQuery({
        variables: {
          filter: {
            ad_url: store.trackingParams.ad_url,
          },
        },
      })

      if (adResult.value?.publicAdFindOne) {
        const adTracking = {
          ad_id: adResult.value.publicAdFindOne.document_id,
          url: store.trackingParams.to_url,
        }
        // 広告トラッキング情報を追加
        await createAdTracking({ record: adTracking })
      }
    } catch {
      console.error("広告トラッキング情報の取得・追加に失敗しました")
    }
  }
}

const meta = (): MetaInfo => {
  const script: (ScriptPropertyText | ScriptPropertySrc)[] = []

  script.push({ innerHTML: newrelic.value || "" })
  script.push(fbPixel)
  script.push(yahooRetargetingTag)
  script.push(yahooRetargetingScript)
  script.push(yahooGeneralTag)
  script.push(criteoTag)
  script.push(crossoverTag)
  script.push(mercariRetargetingTag)
  script.push(mercariGeneralTag)
  // script.push(ugcCoreTag)
  return customMeta.fetch({
    script,
  })
}

const karteViewEvent = () => {
  const { vueApp } = nuxtApp
  if (vueApp.$nuxt.$router) {
    const isJournalPage = route.fullPath.includes("journals")
    if (!isJournalPage && window.krt && ["production"].includes(config.public.APP_ENV)) {
      window.krt("view")
    }
  }
}

const getCouponCode = () => {
  if (route.query.coupon_code) {
    const coupon_code = typeof route.query.coupon_code === "string" ? route.query.coupon_code : ""
    const expired = dayjs().add(3, "week").unix()
    const payload = {
      couponCode: coupon_code,
      expired,
    }
    store.setCouponCode(payload)
  }
}

onMounted(async () => {
  getCouponCode()
  addRouteLog(config.public.BASE_URL)
  await addTrackingInformation(config.public.BASE_URL)
  karteViewEvent()
  appendKarteScripts(route.fullPath)
  setVisitStore()
})

watch(
  () => isShowSearchSideBarSP.value,
  (value) => {
    if (value) {
      window.history.pushState(null, "", null)
      window.addEventListener("popstate", closeSearchSideBarSP)
    } else {
      window.removeEventListener("popstate", closeSearchSideBarSP)
    }
  },
)

watch(
  () => route.fullPath,
  () => {
    appendKarteScripts(route.fullPath)
    removeKarteScripts(route.fullPath)
    addRouteLog(config.public.BASE_URL)
    karteViewEvent()
    cartState.isShowAddCartModal = false
    cartState.quickViewProductId = ""
    checkAndUpdateExpiration()
  },
)

useHead(meta())
</script>

<style module lang="scss" scoped>
.layout_default_content {
  .main_content {
    flex-grow: 1;
    padding-top: 106px;
    padding-bottom: 80px;
    @include md {
      padding-bottom: 100px;
      &.no_show_banner {
        padding-top: 56px;
      }
    }
    &.no_show_banner {
      padding-top: 61px;
    }
    &.not_padding_bottom {
      padding-bottom: 0;
    }
  }

  .search_sidebar {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 120;
    width: 85vw;
    height: 100vh;
    padding: 4.5rem 2rem 2rem;
    background: $primary-10;
    transform: translateX(100%);
    transition: transform 0.3s ease;
    &.is_show {
      transform: translateX(0);
    }
    .close {
      position: absolute;
      top: 30px;
      right: 30px;
      padding: 15px;
      cursor: pointer;
      &::before,
      &::after {
        position: absolute;
        top: 14px;
        left: 0px;
        width: 32px;
        height: 1px;
        content: "";
        background: $primary;
      }
      &::before {
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }
    }
  }

  .sidebar_overlay,
  .search_sidebar_overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    display: none;
    width: 100%;
    height: 100%;
    background-color: rgba($primary, 0.85);
    &.is_show {
      display: block;
    }
  }
}
</style>
