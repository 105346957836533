<template>
  <ClientOnly>
    <footer :class="[$style.footer, { [$style.bottom_button_footer]: isBottomCartButton }]">
      <div :class="$style.footer_inner">
        <div v-if="!isSimple" :class="$style.footer_top">
          <div :class="$style.button_group">
            <AtomsConsumerLink v-if="!isSigned" url="/sign_up" :class="$style.link">
              <MoleculesConsumerButtonWithArrowRight variant="primary" block
                >新規会員登録</MoleculesConsumerButtonWithArrowRight
              >
            </AtomsConsumerLink>
            <AtomsConsumerLink :url="isSigned ? '/mypage' : '/sign_in'" :class="$style.link">
              <MoleculesConsumerButtonWithArrowRight variant="transparent" block>{{
                isSigned ? "マイページ" : "ログイン"
              }}</MoleculesConsumerButtonWithArrowRight>
            </AtomsConsumerLink>
            <AtomsConsumerLink url="/support" :class="$style.link">
              <MoleculesConsumerButtonWithArrowRight variant="transparent" block>
                よくある質問
              </MoleculesConsumerButtonWithArrowRight>
            </AtomsConsumerLink>
          </div>
          <ul :class="$style.nav_links_group">
            <li v-for="navigation in pcNavigations" :key="navigation.document_id" :class="$style.nav_links_title">
              {{ navigation.title }}
              <ul :class="$style.nav_links_wrap">
                <li :class="[$style.nav_link_wrap, { [$style.grid]: navigation.category_urls.length >= 6 }]">
                  <AtomsConsumerLink
                    v-for="nameAndUrl in navigation.category_urls"
                    :key="nameAndUrl?.name"
                    :url="nameAndUrl?.url"
                    :class="$style.nav_link"
                    >{{ nameAndUrl?.name }}</AtomsConsumerLink
                  >
                </li>
              </ul>
            </li>
          </ul>

          <div :class="$style.sp_nav_wrap">
            <template v-for="(navigation, index) in spNavigations" :key="navigation.document_id">
              <MoleculesConsumerAccordion
                v-if="navigation.layout_type === 'head'"
                :class="[
                  $style.accordion,
                  { [$style.last_accordion]: index === spNavigations.length - 1 },
                  { [$style.active]: selectAccordions.includes(index) },
                ]"
                icon="arrow"
                @click="onClickAccordion(index)"
              >
                <template #title>
                  <h4 :class="$style.title">{{ navigation.title }}</h4>
                </template>
                <template #content>
                  <div :class="$style.content">
                    <AtomsConsumerLink
                      v-for="nameAndUrl in navigation.category_urls"
                      :key="nameAndUrl?.name"
                      :url="nameAndUrl?.url"
                      :class="$style.nav_link"
                      >{{ nameAndUrl?.name }}</AtomsConsumerLink
                    >
                  </div>
                </template>
              </MoleculesConsumerAccordion>

              <div v-else :class="[$style.link_type, { [$style.last_link_type]: index === spNavigations.length - 1 }]">
                <AtomsConsumerLink :url="navigation.url" :class="$style.link">
                  <h4 :class="$style.title">{{ navigation.title }}</h4>
                </AtomsConsumerLink>
              </div>
            </template>
          </div>

          <div :class="$style.footer_logo_wrap_pc">
            <MoleculesConsumerTentialLogoLink variant="multiple" :width="70" :height="70" :class="$style.logo" />
            <OrganismsSns :class="$style.sns_wrap" />
          </div>
        </div>
        <div :class="[$style.footer_bottom, { [$style.simple]: isSimple }]">
          <div :class="$style.footer_bottom_menu">
            <AtomsConsumerLink v-for="menu in bottomMenu" :key="menu.title" :url="menu.url">
              <li>{{ menu.title }}</li>
            </AtomsConsumerLink>
          </div>
          <div :class="$style.footer_logo_wrap">
            <MoleculesConsumerTentialLogoLink variant="multiple" :width="58" :height="58" />
            <OrganismsSns :class="$style.sns_wrap" />
          </div>
          <p :class="$style.copyright">©{{ year }} TENTIAL</p>
        </div>
      </div>
    </footer>
  </ClientOnly>
</template>

<script setup lang="ts">
import { useStore } from "~/stores"
import type { ApiLayoutOne } from "~/types/server/api/layout"

const props = withDefaults(
  defineProps<{
    navigations: ApiLayoutOne["navigation"]
  }>(),
  {
    navigations: () => [],
  },
)
const store = useStore()
const year = ref<Number>(0)
const route = useRoute()
const isBottomCartButton = ref(false)

const pcNavigations = computed(() => {
  return props.navigations.filter((nav) => nav.display_type === "pc" || nav.display_type === "all")
})

const spNavigations = computed(() => {
  return props.navigations.filter((nav) => nav.display_type === "sp" || nav.display_type === "all")
})

const isSimple = computed(() => {
  // 簡素表示するページのパス
  const targetPath = ["/order/new"]
  return targetPath.includes(route.path)
})

const isSigned = computed(() => {
  return store.user.isSigned
})

const checkBottomCartButton = () => {
  isBottomCartButton.value = !!document.getElementById("page_parts_bottom_cart_button")
}

const bottomMenu = [
  {
    title: "運営会社について",
    url: "https://corp.tential.jp/",
  },
  {
    title: "採用情報",
    url: "https://corp.tential.jp/recruit/?utm_source=tential_ec&utm_medium=referral",
  },
  {
    title: "プライバシーポリシー",
    url: "https://corp.tential.jp/privacy/",
  },
  {
    title: "特定商取引法の表記",
    url: "/law_info",
  },
  {
    title: "利用規約",
    url: "/terms",
  },
]

const selectAccordions = ref<number[]>([])

const onClickAccordion = (index: number) => {
  if (selectAccordions.value.includes(index)) {
    selectAccordions.value = selectAccordions.value.filter((i) => i !== index)
  } else {
    selectAccordions.value = [...selectAccordions.value, index]
  }
}

year.value = new Date().getFullYear()

watch(
  () => route.fullPath,
  () => {
    checkBottomCartButton()
  },
)

onMounted(() => {
  checkBottomCartButton()
})
</script>
<style module scoped lang="scss">
.footer {
  contain-intrinsic-width: auto 1010px;
  @include main-font;
  background-color: $primary-10;
  padding: 2rem 0.5rem 1.875rem;
  list-style: none;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 5;

  @include md {
    padding: 3.75rem 0;
    padding-top: 3rem;
  }

  &.bottom_button_footer {
    @include md {
      padding-bottom: 8rem;
    }
  }

  .nav_link {
    @include main-font;
    color: $primary-100;
    font-weight: 600;
    display: block;
    &:hover {
      text-decoration: underline;
    }
    @include md {
      width: fit-content;
    }
  }
  .sns_wrap {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    @include md {
      margin-top: 0;
    }
  }
  .footer_inner {
    @include container-lg;
    .footer_top {
      @include md {
        display: flex;
        gap: 5vw;
        margin-bottom: 2.5rem;
      }
      .button_group {
        gap: 0.5rem;
        display: flex;
        flex-direction: column;
        margin-bottom: 2.5rem;
        white-space: nowrap;
        @include md {
          width: 220px;
          margin-bottom: 0;
        }
      }
      .link {
        display: block;
      }
      .nav_links_group {
        display: none;
        @include lg {
          display: flex;
          flex-wrap: wrap;
          gap: 3rem;
        }
        li {
          list-style: none;
        }
        .nav_links_title {
          @include main-font;
          color: $primary-60;
          font-size: $font-size-12;
        }
        .nav_links_wrap {
          margin-top: 1rem;
          .nav_link_wrap {
            @include main-font;
            color: $primary-100;
            font-size: $font-size-13;
            &.grid {
              display: grid;
              grid-auto-flow: column;
              grid-template-rows: repeat(6, auto);
              grid-template-columns: repeat(3, minmax(0, 1fr));
              gap: 0.25rem 3rem;
              padding-bottom: 3rem;
              border-bottom: 1px solid $primary-40;
            }
          }
        }
      }
      .sp_nav_wrap {
        display: flex;
        flex-direction: column;
        width: 100%;
        @include md {
          width: 200px;
        }
        @include lg {
          display: none;
        }
        .title {
          color: $secondary-100;
          font-size: $font-size-13;
        }
        .accordion {
          border-top: 1px solid $secondary-40;
          padding: 1rem 0;
          &.last_accordion {
            border-bottom: 1px solid $secondary-40;
          }
          &.active {
            border-top: 1px solid $primary-100;
            .title {
              color: $primary-100;
            }
          }

          .content {
            margin-top: 0.75rem;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
          }
        }
        .link_type {
          border-top: 1px solid $secondary-40;
          padding: 1rem 0;
          &.last_link_type {
            border-bottom: 1px solid $secondary-40;
          }
        }
      }
      .corporation_wrap {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-top: 2.5rem;
        @include md {
          margin-top: 0;
        }
        p {
          font-size: $font-size-12;
          font-weight: 400;
          color: $primary-100;
          margin-top: 0.5rem;
        }
        .corporation_img {
          max-width: 200px;
          aspect-ratio: 2 / 1;
          object-fit: cover;
          @include md {
            width: 15vw;
          }
        }
      }
      .footer_logo_wrap_pc {
        display: none;
        @include md {
          margin-left: auto;
          display: flex;
          gap: 5rem;
          flex-direction: column;
          align-items: flex-end;
        }
      }
    }
    .footer_bottom {
      @include md {
        border-top: 1px solid $primary-40;
        padding-top: 2.5rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        &.simple {
          border-top: none;
          padding-top: 0;
        }
      }
      .footer_bottom_menu {
        margin-top: 1.5rem;
        font-size: $font-size-11;
        padding-bottom: 1rem;
        border-bottom: 1px solid $primary-40;
        gap: 0.5rem;
        display: grid;
        a {
          color: $primary-80;
        }
        @include md {
          gap: 1.5rem;
          border-bottom: none;
          display: flex;
          padding-bottom: 0;
          margin-top: 0;
          font-size: $font-size-12;
        }
      }
      .footer_logo_wrap {
        display: flex;
        gap: 1.5rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 2.5rem;
        margin-bottom: 1.5rem;
        @include md {
          display: none;
        }
      }
      .copyright {
        @include main-font;
        color: $primary-60;
        text-align: center;
        font-size: $font-size-10;
        @include md {
          font-size: $font-size-12;
        }
      }
    }
  }
}
</style>
